<template>
<div class="flex justify-center w-full" v-loading="loading">
    <div class="login mw-1920">
        <div class="login-item login-left">
            <div class="login-title">
                <h1>Авторизація</h1>
                <p>З поверненням, пройдіть  авторизацію</p>
            </div>

            <div class="flex justify-center" >
                  <el-form :model="login" :rules="loginRules" class="login-card" ref="loginForm">
                    <div class="w-full">
                        <div class="w-full error-login" v-if="errorLogin">
                            <p>Помилка входу або пароля</p>
                        </div>
                        <el-form-item prop="email" class="w-full" :class="errorLogin?'is-error':''">
                            <el-input placeholder="Електронна пошта" v-model="login.email"></el-input>
                        </el-form-item>
                        <el-form-item prop="password" class="w-full" :class="errorLogin?'is-error':''">
                            <div class="password-input">
                                <el-input placeholder="Пароль" class="with-icon" v-model="login.password" :type="password_show?'text':'password'"></el-input>
                                <div class="show-password">
                                    <img :src="password_show? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show=!password_show" alt="">
                                </div>
                            </div>
                        </el-form-item>
                        <div class="black-check">
                            <blackCheck />
                            <p>Запам’ятати</p>
                        </div>
                        <div class="flex login-button">
                            <button type="button" class="button" @click="loginFun">Увійти</button>
                        </div>
                        <div class="reset-link">
                            <router-link to="/recovepassword" tag="a">
                                Забули пароль?
                            </router-link>
                        </div>
                    </div>
                </el-form>
            </div>
            
            
        </div>
        <div class="login-item">
            <div class="login-right">
                <div class="w-full flex justify-center">
                    <img src="@/assets/img/big-a.svg" alt="">
                </div>
            </div>
            <div class="login-cover"> 
                <img src="@/assets/img/login-bottom.svg" class="desktop" alt="">
                <img src="@/assets/img/login-phone-bottom.svg" class="phone" alt="">
            </div>
            <div class="login-clouds desktop">
                <img src="@/assets/img/login-clouds.svg" alt="">
            </div>
        </div>
       
    </div>
    </div>
</template>
<script> 
import blackCheck from '@/components/check-black.vue'
export default  {
    components:{
        blackCheck
    },
    data() {
            return{
                input:'',
                password_show:false,
                errorLogin:false,
            loading:false,
            login: {
                email: '',
                password: ''
            },
            loginRules: {
                email: [{
                    required: true, 
                    message: 'Введіть свою електронну адресу',
                    trigger: 'change'
                },
                { 
                    type: 'email',
                    message: 'Невірно вказана електронна скринька',
                    trigger: 'change'
                },  ],
                password: [{
                    required: true,
                    message: 'Введіть ваш пароль',
                    trigger: 'change'
                }, ]
            }
            }
    },
        watch:{
        'login.email'(){ this.errorLogin=false},
        'login.password'(){ this.errorLogin=false}
    },
    methods: {
        loginFun() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    try{
                        this.loading=true
                        this.axios.post('/login', this.login).then(res => {
                        this.$store.commit('get_me', {
                            payload: res.data.data.user
                        })
                        localStorage.token = res.data.data.token
                        localStorage.role= res.data.data.user.role_id
                        this.axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.token}`;
                        if(res.data.data.user.role_id==1){
                            this.$router.push('/admin')
                        }
                        else{
                            this.$router.push('/')
                        }
                        
                        this.$notify({
                            title: 'Успешный',
                            message: 'Ви успішно ввійшли',
                            type: 'success'
                        });
                    })
                    .catch(e=>{
                        console.log(e)
                        this.errorLogin=true
                          this.$notify.error({
                                title: 'Error',
                                message: 'Помилка входу або пароля'
                            });
                    })
                    .finally(()=>{
                        this.loading=false
                    })
                    }
                    catch{
                        console.log("AAA")
                    }
                   
                }
            })
        }
    }
}
</script>
<style lang="scss">
.login {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background: var(--bg-top);
    width: 100%;

}

.login-item {
    padding: 200px 100px;
    position: relative;
    z-index: 1;
}

.login-item:nth-child(1) {
    background: var(--white-bg);
    width: 55%;
}

.login-item:nth-child(2) {
    width: 45%;
}

.login-cover {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 100%;
    }
}

.login-title {
    h1 {
        font-family: Stem-Medium;
        font-size: 51px;
        line-height: 60px;
        color: var(--other-black);
        text-align: center;
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        color: var(--white-black);
        margin-top: 20px;
        text-align: center;
    }
}
.error-login{
    padding: 0px 20px 20px 20px;
    text-align: center;
    color: #FF0000;

}
.login-card {
    width: 100%;
    max-width: 600px;
    padding: 60px;
    box-shadow: 0px 6px 16px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px rgba(0, 72, 153, 0.03);
    background: var(--white-bg);
    border-radius: 10px;
    margin-top: 60px;

    .el-input__inner {
        border: none;
        border-bottom: 1.5px solid #DDDFE2;
        padding: 20px;
        height: auto;
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: var(--white-black);
        border-radius: 0;

    }

    .el-form-item__error {
        font-family: Stem-Regular;
    }

    .el-form-item.is-error .el-input__inner {
        color: #FF0000;
    }
}

.black-check {
    margin-top: 20px;
    display: flex;
    align-items: center;

    p {
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        margin-left: 10px;
        color: var(--white-black);
    }
}

.login-button {
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.reset-link {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    a {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        text-decoration-line: underline;

        color: var(--other-black);
    }
}

.login-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    p {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--other-black);
        margin-bottom: 40px;
        text-align: center;
    }
}

.password-input {
    position: relative;

    .show-password {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            cursor: pointer;
        }
    }

}

.login-clouds {
    position: absolute;
    top: 100px;
    right: 0px;
    z-index: 0;
    width: 100%;
    padding: 100px;

    img {
        width: 100%;
    }
}

@media screen and(max-width: 1200px) {
    .login-card {
        padding: 40px;
    }
}

@media screen and(max-width: 1130px) {
    .login-card {
        padding: 30px;
    }

    .login-item {
        padding: 200px 20px;
    }

    .login-button {
        margin-top: 40px;
    }
}

@media screen and(max-width: 768px) {
    .login-item:nth-child(1) {
        width: 100%;
        background: var(--bg-top);
    }

    .login-item:nth-child(2) {
        width: 100%;
        padding: 50px 20px;
    }

    .login-cover {

        img {
            width: 50%;
        }
    }

    .login-title {
        h1 {
            font-size: 24px;
            line-height: 28px;
        }

        p {
            font-size: 0.875rem;
            line-height: 1rem;
            margin-top: 10px;
        }
    }

    .login-card {
        max-width: 450px;
        margin-top: 40px;
        padding: 40px 20px;

        .el-input__inner {
            padding: 18px;
            font-size: 1rem;
            line-height: 1.125rem;

        }
    }

    .black-check {
        p {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }

    .reset-link {
        a {
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }
}

@media screen and(max-width: 500px) {
    .login-item:nth-child(1) {
        width: 100%;
        padding: 150px 0px 90px 0px;
    }

    .login-item:nth-child(2) {
        width: 100%;
        padding: 0px 20px 100px 20px;
    }

    .login-cover {

        img {
            width: 50%;
        }
    }
}
</style>
